import 'regenerator-runtime/runtime';
import * as bootstrap from 'bootstrap';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Debounced from 'debounced';
import CableReady from 'cable_ready';
import applicationController from './controllers/application_controller';
import applicationConsumer from './channels/consumer';
import StimulusReflex from 'stimulus_reflex';
import { Autocomplete } from 'stimulus-autocomplete';
import TextareaAutogrow from 'stimulus-textarea-autogrow';

/* ---------------------------------------------------------------------------------------------- *\
**                                           Init Rails                                           **
\* ---------------------------------------------------------------------------------------------- */

ActiveStorage.start();

/* ---------------------------------------------------------------------------------------------- *\
**                                     Initialize Bootstrap 5                                     **
\* ---------------------------------------------------------------------------------------------- */

var prepareElements = function prepareElements() {
  var tooltipTriggers = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggers.map(function (tooltipTriggerElement) {
    return new bootstrap.Tooltip(tooltipTriggerElement);
  });
  var popoverTriggers = Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggers.map(function (popoverTriggerElement) {
    return new bootstrap.Popover(popoverTriggerElement);
  });
  var dropdownToggles = Array.from(document.querySelectorAll('[data-bs-toggle]'));
  dropdownToggles.map(function (dropdownToggleElement) {
    new bootstrap.Dropdown(dropdownToggleElement, {
      popperConfig: {
        strategy: 'fixed'
      }
    });
  });
};
prepareElements();
addEventListener('turbo:render', prepareElements);
Debounced.initialize();

/* ---------------------------------------------------------------------------------------------- *\
**                                         Action Cable                                           **
\* ---------------------------------------------------------------------------------------------- */

var channels = require.context('./channels', true, /_channel\.js$/);
channels.keys().forEach(channels);

/* ---------------------------------------------------------------------------------------------- *\
**                                     Initialize Stimulus                                        **
\* ---------------------------------------------------------------------------------------------- */

var application = Application.start();
application.debug = process.env.RAILS_ENV === 'development';
var controllersContext = require.context('./controllers', true, /\.js$/);
var controllerDefinitions = definitionsFromContext(controllersContext);
application.load(controllerDefinitions);
window.Stimulus = application;

/* ---------------------------------------------------------------------------------------------- *\
**                                  Initialize Stimulus Reflex                                    **
\* ---------------------------------------------------------------------------------------------- */

StimulusReflex.initialize(application, {
  controller: applicationController,
  isolate: true,
  debug: process.env.RAILS_ENV === 'development'
});
application.consumer = applicationConsumer;
CableReady.initialize({
  consumer: applicationConsumer
});

/* ---------------------------------------------------------------------------------------------- *\
**                                 Vendors Stimulus Controllers                                   **
\* ---------------------------------------------------------------------------------------------- */

application.register('autocomplete', Autocomplete);
application.register('textarea-autogrow', TextareaAutogrow);

/* ---------------------------------------------------------------------------------------------- *\
**                                          Turbo custom                                          **
\* ---------------------------------------------------------------------------------------------- */

// addEventListener('turbo:load', ({ target }) => { });
// addEventListener('turbo:render', ({ target }) => { });
// addEventListener('turbo:visit', ({ target }) => { });